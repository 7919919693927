/*
 *  Document   : _breadscrumbs.scss
 *  Author     : RedStar Template
 *  Description: This scss file for breadscrumbs style classes
 */

.breadcrumb {
  border-radius: 0px;
  background-color: transparent;
  font-size: 15px;
  margin-bottom: 0px;
  padding: 0.75rem 0rem;
  color: #555;

  &:before {
    content: "";
    margin: 0;
  }

  li {
    display: inline-block;

    a {
      color: #444;
      text-decoration: none;

      .fa-home {
        font-size: 17px;
        position: relative;
        top: 0;
        float: left;
        padding-left: 10px;
        color: #444444;
      }
    }

    .material-icons {
      font-size: 18px;
      position: relative;
      top: 4px;
      float: none;
    }

    &.active {
      color: #444444;
    }
  }

  > li + li:before {
    content: "/" !important;
    color: #444444;
    padding-left: 5px;
  }

  .page-title {
    border-right: 1px solid #c5c5c5;
    padding-right: 10px;
    font-size: 23px;
    font-weight: 500;
    color: #444444;
  }

  .breadcrumb-item + .breadcrumb-item {
    padding-top: 3px;
    padding-left: 0px;

    &::before {
      float: unset;
      padding-right: 5px;
    }
  }
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

.doc-card-image {
  background: #fff;
  position: inherit;
  padding: 2px;
  box-shadow: 0px 5px 25px 0px #888888;
  width: 150px;
  border-radius: 10px;
}

.doc-card-title {
  color: #00bdf2 !important;
  font-size: 16px;
}

.stars {
  .col-orange {
    color: orange;
  }
}

.contact-icons-container {
  margin: 0px -8px;

  .icon-btn-margin {
    margin: 3px;

    .visibility-icon {
      color: #32a932;
    }

    .delete-icon {
      color: #000000;
    }

    .forum-icon {
      color: #90ca4b;
    }
    .share-icon {
      color: #727cb6;
    }

    .picture-as-pdf-icon {
      color: #ff0000;
    }
    .room-icon {
      color: #ff5b57;
    }

    .print-icon {
      color: #8753de;
    }

    .voice-icon {
      color: #6c757d;
    }
  }
}

//   @each $key, $val in $colors {
//     .breadcrumb-col-#{$key} {
//       li {
//         a {
//           color: $val !important;
//           font-weight: bold;
//         }
//       }
//     }

//     .breadcrumb-bg-#{$key} {
//       background-color: $val !important;

//       li {
//         a {
//           color: #fff;
//           font-weight: bold;

//           .material-icons {
//             padding-bottom: 8px;
//           }
//         }

//         color: #fff !important;
//       }

//       li + li:before {
//         color: #fff;
//       }
//     }
//   }
//   .breadcrumb-style {
//     border-radius: 30px;
//     /*background: #ffffff;
//       padding-left: 20px !important;*/
//   }
//   .breadcrumb-chart {
//     display: inline-block;
//     .chart-info p {
//       font-size: 13px;
//     }
//   }

.custom-dropdown-button .dropdown-toggle::after {
  display: none;
}
.custom-dropdown-button {
  > button {
    border-radius: 50%;
    height: 50px;
    width: 50px;
    outline: none;
    border: none;
    background-color: white;
    > svg {
      text-align: center;
      position: relative;
      color: #000000;
      font-size: 2rem;
    }
    &:focus {
      outline: none;
      box-shadow: unset;
    }
  }
}
.add-contact-form {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.add-contact-dialog-title {
  padding-bottom: 8px;
}

.add-contact-dialog-content {
  width: 500px;
  height: 800px;
  overflow-y: auto;
}

.add-contact-dialog-actions {
  padding-top: 8px;
}

.add-contact-button {
  margin-right: 8px;
}
.bcrumb-1 {
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.add-button-contacts {
  border-color: #00acac !important;
  color: #00acac !important;
  &:focus {
    border-color: #00acac !important;
  }
  &:hover {
    border: #00acac !important;
  }
}
.dialogue-content-add-contact {
  height: 500px;
  transition: all;
}
html,
body {
  height: 100%;
  margin: 0;
}

.container-fluid {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.row {
  flex: 1;
}

.col-lg-3,
.col-md-4,
.col-sm-6 {
  height: 100%;
  padding: 15px;
}

.bg-light {
  background-color: #f8f9fa;
}

.col-lg-9,
.col-md-8,
.col-sm-6 {
  height: 100%;
  padding: 15px;
}

.bg-dark {
  background-color: #343a40;
}

h2 {
  color: white;
}
