.my-video {
  height: 140px;
  width: 200px;
  background-color: black;
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px grey solid;
  z-index: 10;
}
.audio {
  background-color: white;
  color: black;
}
.remote-video {
  width: 60%;
  position: relative;
}

.remote-audio {
  width: 60%;
  background-color: white;
  color: black;
  height: 500px;
  position: relative;
}
#call-chat{
  display: none;
}

@media (min-width: 768px) {
  #call-chat{
    display: block;
  }
}