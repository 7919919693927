.my-video {
  height: 150px;
  width: 20%;
  background-color: black;
  position: relative;
}
.audio {
  background-color: white;
  color: black;
}
.remote-video {
  width: 70%;
}

.remote-audio {
  width: 70%;
  background-color: white;
  color: black;
  height: 500px;
}

@media only screen and (max-width: 660px) {
  .my-video {
    position: absolute;
    top: 10%;
    right: 10%;
    height: 150px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: black; */
    border: 1px grey solid;
  }
  .audio {
    background-color: white;
    color: black;
  }
  .remote-video {
    width: 100%;
  }
  .remote-audio {
    width: 100%;
    background-color: white;
    color: black;
    height: 500px;
  }
}
