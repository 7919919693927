#join-meeting-wrapper{
    width: 60%;
    margin: auto;
    height: 500px;
    /* background-color: antiquewhite; */
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0px;
    
}
#join-meeting-form{
    width: 50%;
}
.btn-join{
    width: 100%;
}
#my-video{
    height: 500px;
    width: 100%;
    margin-top: -100px;
}
#users-stream{
    height: 160px;
    width: 100%;
    /* background-color: aqua; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-y: hidden; /* Hide vertical scrollbar */
  overflow-x: scroll;
}

::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
    border-radius: 3px;
}
::-webkit-scrollbar
{
	width: 6px;
    
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	background-color: #a8a8a8;
    border-radius: 3px;
}

.active-userinfo{
    position: relative;
    width: 200px;
    height: 50px;
    top: 20px;
    margin-left: 20px;
    z-index: 1;
    
    
}
#meet-h1{
    width: 30%;
    text-align: center;
    /* margin-top: 15px; */
    background-color: aliceblue;
    padding: 10px;
    border-radius: 30px;
    margin: 8px auto 0px auto;
    
}
.userinfo{
    position: relative;
    height: 50px;
    top: 100px;
    left: 10px;
    z-index: 1;
    
}
.participant{
    background-color: rgb(236, 248, 250);
    width: 100%;
    border-radius: 5px;
    margin-left: -10px;
    padding: 7px;
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
}
.participant:hover{
    background-color: antiquewhite;
}
#controls{
    position: relative;
    top: 390px;
    height: 50px;
    margin: 0px auto;
    z-index: 1;
}
.controls-btn{
    cursor: pointer;
}
#videomeeting-session{
    width: 65%;

}   
#participants-tab{
    width: 35%;
    overflow: hidden;
    
}
@media only screen and (max-width: 765px){
    #videomeeting-session{
        width: 100%;
    
    }   
    #participants-tab{
        display: none;
        
    }
}
.users-details{
    height: 87vh;
    /* margin-top: 1vh; */
    background-color: white;
    width: 100%;
    padding:10px 10px;
  
}
#users-list{
    display: flex;
    flex-direction: column;
    position: absolute;
    top:-100px;
    width: 100%;
    /* justify-content: flex-start; */
    /* align-items: flex-start; */
}
.participants-controls{
    float: right;
}

.participants-controls img{
    display: inline-block;
    margin: 0px 7px;
    align-self: flex-end;
}
.cursor-pointer{
    cursor: pointer;
}